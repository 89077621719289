<template>
  <el-main><router-view /></el-main>
</template>
<script>
export default {
  name: "AuthLayout",
};
</script>
<style scoped>
.el-main {
  margin-left: 0px;
}
</style>